import { SystemProps, Flex, Box, Grid } from '@storyofams/react-ui'
import { useEffect, useState, useMemo } from 'react'
import { Button, Heading, ProductItemHome } from '~components'
import { shopifySdk } from '~lib/shopify/client'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { SectionWrapper } from '~components/common/SectionWrapper'

type ProductItemProps = {
  texture_type?: number
  product_handle?: string
  product_background_color?: {
    color?: string
  }
}

type HighlightOfTheWeekProps = {
  content: {
    title?: string
    font_color?: {
      color?: string
    }
    background_color?: {
      color?: string
    }
    products?: Array<ProductItemProps>
    product_buy_button_text?: string
    button_text?: string
    button_link?: {
      cached_url?: string
    }
    use_carousel_mobile: boolean
  }
  first?: boolean
} & SystemProps

export const HighlightOfTheWeek = ({ content }: HighlightOfTheWeekProps) => {
  const [productsData, setProductsData] = useState(null)

  useEffect(() => {
    if (
      content?.products &&
      content?.products.length > 0 &&
      productsData == null
    ) {
      const productsHandle = content?.products.map(el => el.product_handle)
      shopifySdk.products({ first: 99 }).then(res => {
        if (res.products?.edges && productsHandle.length > 0) {
          const productItemsData = productsHandle.map(productHandle => {
            const productData = res.products?.edges.find(
              ({ node }) => node.handle == productHandle,
            )

            return productData ? productData?.node : null
          })
          setProductsData(productItemsData)
        }
      })
    }
  }, [content?.products])

  return (
    <SectionWrapper>
      <Flex
        marginTop={'0 !important'}
        width="100%"
        backgroundColor={
          content?.background_color ? content?.background_color.color : 'auto'
        }
        position={'relative'}
        flexDirection={'column'}
        px={[2, 4, 10]}
        py={[6, 6, 10]}
      >
        <Box width="100%" mb={6}>
          {content?.title && (
            <Heading
              as="h1"
              variant="sh1"
              width={'100%'}
              textAlign={'center'}
              color={
                !!content?.font_color ? content?.font_color.color : 'initial'
              }
            >
              {content?.title}
            </Heading>
          )}
        </Box>
        <Box width="100%">
          {productsData && productsData.length > 0 && (
            <>
              <Box
                width="100%"
                display={
                  content?.use_carousel_mobile ? ['none', 'grid'] : 'grid'
                }
                overflowY={['auto', 'auto', 'inherit']}
                style={{
                  columnGap: '16px',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  rowGap: '32px',
                }}
              >
                {productsData.map((product, index) => (
                  <ProductItemHome
                    key={product?.id}
                    product={product}
                    textureType={content?.products[index]?.texture_type}
                    backgroundColor={
                      content?.products[index]?.product_background_color?.color
                    }
                    buttonText={content?.product_buy_button_text}
                    smallText
                  />
                ))}
              </Box>
              {content?.use_carousel_mobile && (
                <Box display={['block', 'none']} maxWidth="100%">
                  <Carousel
                    dynamicHeight={false}
                    showStatus={false}
                    showArrows={true}
                    showIndicators={false}
                    renderArrowNext={(onClickHandler, hasNext) =>
                      hasNext && (
                        <button
                          type="button"
                          onClick={onClickHandler}
                          style={{
                            right: 5,
                            position: 'absolute',
                            zIndex: 2,
                            top: 'calc(50% - 20px)',
                            width: 40,
                            height: 40,
                            cursor: 'pointer',
                            padding: '5px',
                            background: 'white',
                            borderRadius: '50%',
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                          >
                            <path
                              d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
                              fill="#000000"
                            />
                          </svg>
                        </button>
                      )
                    }
                    renderArrowPrev={(onClickHandler, hasPrev) =>
                      hasPrev && (
                        <button
                          type="button"
                          onClick={onClickHandler}
                          style={{
                            left: 5,
                            position: 'absolute',
                            zIndex: 2,
                            top: 'calc(50% - 20px)',
                            width: 40,
                            height: 40,
                            cursor: 'pointer',
                            padding: '5px',
                            background: 'white',
                            borderRadius: '50%',
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                          >
                            <path
                              d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z"
                              fill="#000000"
                            />
                          </svg>
                        </button>
                      )
                    }
                  >
                    {productsData.map((product, index) => (
                      <ProductItemHome
                        key={product?.id}
                        product={product}
                        textureType={content?.products[index]?.texture_type}
                        backgroundColor={
                          content?.products[index]?.product_background_color
                            ?.color
                        }
                        buttonText={content?.product_buy_button_text}
                        smallText
                      />
                    ))}
                  </Carousel>
                </Box>
              )}
            </>
          )}
        </Box>
        {content?.button_text && (
          <Flex
            width={'100%'}
            justifyContent={'center'}
            mt={[content?.use_carousel_mobile ? 3 : 5, 5]}
          >
            <Button
              variant={'button_type_1'}
              to={content?.button_link?.cached_url}
            >
              {content?.button_text}
            </Button>
          </Flex>
        )}
      </Flex>
    </SectionWrapper>
  )
}
