import { useState, useMemo, useEffect } from 'react'
import { Box, Flex, SystemProps } from '@storyofams/react-ui'
import { Texture1, Texture2, Texture3, Texture4, Texture5 } from './Textures'
import { FixedRatio, Heading, Button, Text, Image, Select } from '~components'
import {
  formatPrice,
  getPortionName,
  gaAddToCartEvent,
  gaViewItemEvent,
  formatWeight,
} from '~lib'
import { Badge } from '~components/common/Cart/Badge'
import { ProductVariant } from '~lib/shopify/sdk'
import { useShopify } from '~context'
import { shopifyDiscountPriceFactor } from '~config'
import { startsWith } from 'lodash'
import { useRouter } from 'next/router'
import { defineMessages, useIntl } from 'react-intl'

const fontColor = '#F7F3EF'

const messages = defineMessages({
  soldOut: 'sold out',
})

export const ProductItemHome = ({
  product,
  textureType,
  backgroundColor,
  buttonText,
  smallText,
}: {
  product: any
  textureType?: number
  backgroundColor?: string
  buttonText: string
  smallText?: boolean
} & SystemProps) => {
  const {
    addItemToCart,
    updatingCart,
    checkout,
    quantity: cartQuantity,
    checkLocalItemsContains,
    //adjustLineItemQuantity
  } = useShopify()
  const router = useRouter()
  const intl = useIntl()
  const [activeVariantId, setActiveVariantId] = useState(null)
  const [activeVariant, setActiveVariant] = useState(null)
  const textBlockBackgroundColor = backgroundColor

  useEffect(() => {
    if (!activeVariant && !activeVariantId) {
      const variant = product?.variants?.edges?.[0]?.node

      setActiveVariant(variant)
      setActiveVariantId(variant?.id)
    }
  }, [product])

  const productLink = `/meals/${product?.handle}${
    router?.query?.ordering ? '?ordering=true' : ''
  }`

  const setActiveVariantData = variant => {
    setActiveVariant(variant)

    if (!!product && !!variant) {
      gaViewItemEvent({
        currency: variant.priceV2.currencyCode,
        amount: variant.priceV2.amount,
        item: {
          item_id: variant?.sku,
          item_name: product?.title,
          item_brand: product?.vendor,
          item_variant: variant?.title,
          price: Number(variant?.priceV2?.amount),
          quantity: 1,
        },
      })
    }
  }

  const checkoutQuantity = useMemo(() => {
    const checkoutProduct = checkout?.lineItems?.edges?.find(
      ({ node }) => !!activeVariant && node.variant.id == activeVariant.id,
    )?.node

    return checkoutProduct?.quantity || 0
  }, [cartQuantity, activeVariant, checkout])

  const Texture = ({ textureType }) => {
    switch (textureType) {
      case '1': {
        return (
          <Texture1
            textureColor={textBlockBackgroundColor}
            backgroundColor={'#F1EBE5'}
          />
        )
      }
      case '2': {
        return (
          <Texture2
            textureColor={textBlockBackgroundColor}
            backgroundColor={'#F1EBE5'}
          />
        )
      }
      case '3': {
        return (
          <Texture3
            textureColor={textBlockBackgroundColor}
            backgroundColor={'#F1EBE5'}
          />
        )
      }
      case '4': {
        return (
          <Texture4
            textureColor={textBlockBackgroundColor}
            backgroundColor={'#F1EBE5'}
          />
        )
      }
      case '5': {
        return (
          <Texture5
            textureColor={textBlockBackgroundColor}
            backgroundColor={'#F1EBE5'}
          />
        )
      }
      default: {
        return <Texture1 textureColor={'#B55B99'} backgroundColor={'#F1EBE5'} />
      }
    }
  }

  const OneVariant = ({ variant, checkoutLineItems }) => {
    const variantPrice = {
      amount: (variant?.priceV2?.amount * shopifyDiscountPriceFactor).toFixed(
        2,
      ),
      currencyCode: variant?.priceV2?.currencyCode,
    }
    const checkoutProduct =
      checkoutLineItems?.edges?.find(
        ({ node }) => node?.variant?.id == variant?.id,
      )?.node || checkLocalItemsContains(variant?.id)

    return (
      <Flex
        alignItems={'flex-start'}
        width={'100%'}
        flexDirection={['column']}
        ml={1.5}
        mt={1.5}
      >
        <Text
          width={'fit-content'}
          textAlign={'center'}
          color={fontColor}
          fontSize={['26px', '20px', '26px', '25px']}
          fontWeight={'bold'}
          lineHeight={'1em'}
          style={{
            overflowWrap: 'anywhere',
          }}
          position="relative"
        >
          {variant?.quantityAvailable <= 0
            ? `${intl.formatMessage(messages.soldOut)}`
            : `${formatPrice(variantPrice)}`}
          {checkoutProduct?.quantity > 0 ? (
            <Badge
              display={'inline-flex'}
              position="absolute"
              top={'-5px'}
              right={'-15px'}
              bg={'#21857F'}
              ml={[0.5]}
              fontSize={'16px'}
              width={'19px'}
              height={'19px'}
              alignItems={'baseline'}
            >
              <span style={{ lineHeight: '1.35em' }}>
                {checkoutProduct?.quantity}
              </span>
            </Badge>
          ) : (
            ''
          )}
        </Text>
        <Text
          width={'fit-content'}
          color={fontColor}
          fontSize={['20px']}
          fontWeight={'bold'}
          lineHeight={'1em'}
          style={{
            overflowWrap: 'anywhere',
          }}
        >
          {`(${formatWeight(variant?.weight, variant?.weightUnit)})`}
        </Text>
      </Flex>
    )
  }

  return (
    <Flex
      width={'100%'}
      flexDirection={'column'}
      minWidth={'310px'}
      height={['100%', 'auto']}
    >
      {product?.images?.edges && product?.images.edges.length > 0 && (
        <Button to={productLink} variant="unstyled">
          <Box bg={'#F1EBE5'} width={'100%'}>
            <Box width={'100%'} mx={'auto'}>
              <FixedRatio ratio={[[1, 1]]} overflow="hidden">
                <Image
                  src={product?.images?.edges[0]?.node?.url}
                  width={'100%'}
                  height={'100%'}
                  layout="fill"
                  objectFit="contain"
                  quality={100}
                />
              </FixedRatio>
            </Box>
          </Box>
        </Button>
      )}
      <Button to={productLink} variant="unstyled" height={'fit-content'}>
        <FixedRatio ratio={[[300, 42]]} overflow="hidden">
          <FixedRatio ratio={[[308, 64]]} overflow="hidden">
            <Texture textureType={textureType} />
          </FixedRatio>
        </FixedRatio>
      </Button>
      <Box height={'100%'} minHeight={'fit-content'} bg={backgroundColor}>
        <Flex
          pt={[2]}
          pb={[4]}
          px={[3]}
          flexDirection={'column'}
          height={'100%'}
          minHeight={'fit-content'}
          justifyContent={'space-between'}
        >
          <Box>
            <Button
              to={productLink}
              variant="unstyled"
              width="100%"
              height="100%"
            >
              <Heading
                as="h2"
                variant="sh2"
                width={'100%'}
                color={fontColor}
                fontSize={smallText ? ['25x', '25px'] : []}
                style={{
                  overflowWrap: 'anywhere',
                }}
              >
                {product?.title}
              </Heading>
              <Text
                mt={1}
                color={fontColor}
                lineHeight={'130%'}
                fontSize={smallText ? '16px' : '20px'}
              >
                {product?.description}
              </Text>
            </Button>
          </Box>
          <Flex mt={4} justifyContent={'space-between'} flexDirection={['row']}>
            {product?.variants?.edges?.length > 1 ? (
              <Select
                menuPortalTarget={document.body}
                width={'100%'}
                maxWidth={['100%', '100%', '100%', '200px']}
                options={product?.variants?.edges.map(variant => {
                  const variantData = variant.node as ProductVariant
                  const checkoutProduct =
                    checkout?.lineItems?.edges?.find(
                      ({ node }) => node.variant.id == variant.node?.id,
                    )?.node || checkLocalItemsContains(variant.node?.id)
                  const portion = variantData.selectedOptions.find(
                    el => el.name === 'Portion',
                  )
                  const priceData = {
                    ...variantData?.priceV2,
                    amount: !!variantData?.priceV2?.amount
                      ? (
                          variantData?.priceV2?.amount *
                          shopifyDiscountPriceFactor
                        ).toFixed(2)
                      : 0,
                  }
                  const quantityAvailable = variantData?.quantityAvailable || 0
                  return {
                    value: variantData.id,
                    label: (
                      <Flex
                        fontSize={'14px'}
                        display={'flex'}
                        alignItems={'center'}
                      >
                        <Text
                          display={'inline'}
                          fontSize={'14px'}
                          textDecoration={
                            quantityAvailable <= 0 ? 'line-through' : 'none'
                          }
                        >
                          {!!portion ? getPortionName(portion.value) : ''}
                        </Text>
                        {checkoutProduct?.quantity > 0 ? (
                          <Badge
                            display={'inline-flex'}
                            position="relative"
                            bg={'#21857F'}
                            ml={[0.5]}
                            fontSize={'16px'}
                            width={'19px'}
                            height={'19px'}
                            alignItems={'baseline'}
                          >
                            <span style={{ lineHeight: '1.35em' }}>
                              {checkoutProduct?.quantity}
                            </span>
                          </Badge>
                        ) : (
                          ''
                        )}
                        <Text display={'inline'} ml={[1]} fontSize={'14px'}>
                          {quantityAvailable <= 0
                            ? 'soldOut'
                            : `${formatPrice(priceData)}`}
                        </Text>
                      </Flex>
                    ),
                  }
                })}
                onChange={opt => {
                  setActiveVariantId(opt.value)
                  const newActiveVariant = product?.variants?.edges?.find(
                    edge => edge?.node?.id == opt.value,
                  )
                  if (newActiveVariant) {
                    setActiveVariantData(newActiveVariant.node)
                  }
                }}
                value={activeVariantId}
                defaultValue={product?.variants?.edges[0]?.node?.id}
                isSearchable={false}
                customStyles={{
                  '.react-select': {
                    '&__single-value p': {
                      fontWeight: '700',
                      textTransform: 'uppercase',
                    },
                    '&__indicators svg': {
                      fill: `${'black'}`,
                    },
                    '&__control': {
                      borderRadius: '0',
                      borderWidth: '1.5px',
                      borderColor: `${fontColor ? fontColor : 'black'}`,
                      fontSize: 2,
                      fontWeight: '700',
                      height: '45px',
                      justifyContent: 'center',
                      '&:hover': {
                        cursor: 'pointer',
                        bg: 'grey300',
                      },
                      '&--menu-is-open': {
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                    },
                    '&__menu': {
                      zIndex: 2,
                      my: 0,
                      borderRadius: '0',
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderWidth: '1.5px',
                      borderStyle: 'solid',
                      borderColor: `${fontColor ? fontColor : 'black'}`,
                      borderTop: 'none',
                      boxShadow: 'none',
                      overflow: 'hidden',

                      '.react-select__menu-list': {
                        p: 0,
                      },
                      '.react-select__menu-list p': {
                        textTransform: 'uppercase',
                      },
                      '.react-select__option': {
                        px: 1,
                        py: 0.5,
                        mb: 0.2,
                        '&:hover': {
                          cursor: 'pointer',
                        },
                        '&--is-disabled': {
                          color: 'grey400',
                          cursor: 'not-allowed',
                        },
                      },
                    },
                  },
                }}
              />
            ) : (
              <OneVariant
                variant={activeVariant}
                checkoutLineItems={checkout?.lineItems}
              />
            )}

            <Button
              variant={'button_type_2'}
              display={['flex']}
              // mt={[2, 2, 2, 0]}
              fontSize={['19px']}
              ml={[2]}
              disabled={
                updatingCart ||
                (!!activeVariant &&
                  !!checkoutQuantity &&
                  checkoutQuantity >= activeVariant.quantityAvailable) ||
                !activeVariant?.availableForSale
              }
              cursor={updatingCart ? 'wait!important' : undefined}
              onClick={async () => {
                const lineItemType = product?.tags?.find(t =>
                  startsWith(t, 'is_'),
                )
                await addItemToCart(
                  [
                    {
                      quantity: 1,
                      variantId: activeVariantId,
                    },
                  ],
                  lineItemType,
                )
                gaAddToCartEvent({
                  currency: activeVariant?.priceV2?.currencyCode,
                  amount: activeVariant?.priceV2?.amount,
                  items: [
                    {
                      item_id: activeVariant?.sku,
                      item_name: product?.title,
                      item_brand: product?.vendor,
                      item_variant: activeVariant?.title,
                      price: Number(activeVariant?.priceV2?.amount),
                      quantity: 1,
                    },
                  ],
                })
              }}
            >
              {buttonText}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}
